import anime from "animejs";

class Loader {
    constructor() {
        this.animateLoader();
    }

    animateLoader() {
        anime({
            targets: document.getElementById("first-loader-logo"),
            opacity: 1,
            duration: 700,
            easing: "easeInOutSine",
            complete: () => {
                anime({
                    targets: document.getElementById("first-loader-logo"),
                    opacity: 0,
                    duration: 300,
                    easing: "easeInOutSine",
                });

                anime({
                    targets: document.getElementById("second-loader-logo"),
                    opacity: 1,
                    duration: 1000,
                    easing: "easeInOutSine",
                    complete: () => {
                        anime({
                            targets: document.getElementById("loader"),
                            opacity: 0,
                            duration: 300,
                            easing: "easeInOutSine",
                            complete: () => {
                                document.getElementById(
                                    "loader"
                                ).style.display = "none";
                            },
                        });
                    },
                });

                this.animateHeroIntro();
            },
        });
    }

    animateHeroIntro() {
        anime({
            targets: document.getElementById("hero-heading"),
            marginTop: 0,
            opacity: 1,
            delay: 800,
            duration: 1300,
            easing: "easeOutSine",
        });
        anime({
            targets: document.getElementById("hero-video"),
            delay: 1500,
            opacity: 1,
            duration: 300,
            easing: "easeInOutSine",
        });
    }
}

export default Loader;
