import anime from "animejs";

class VideoOverlay {
    constructor() {
        this.elements = {
            body: document.querySelector("body"),
            videoOverlay: document.querySelector("#video-overlay"),
            closeVideo: document.querySelector("#close-video"),
            heroVideo: document.querySelector("#hero-video"),
            video: document.querySelector("#video-full"),
        };
        this.initMobileNav();
    }

    closeVideoOverlay() {
        this.elements.video.pause();
        anime({
            targets: "#video-overlay",
            opacity: 0,
            duration: 200,
            easing: "easeOutSine",
            complete: () => {
                this.elements.videoOverlay.style.display = "none";
            },
        });
        anime({
            targets: "#video-animation-overlay-2",
            height: "0%",
            duration: 300,
            easing: "easeInSine",
        });
        anime({
            targets: "#video-animation-overlay-1",
            height: "0%",
            delay: 300,
            duration: 300,
            easing: "easeInQuint",
        });
    }

    openVideoOverlay() {
        anime({
            targets: "#video-animation-overlay-1",
            height: "100%",
            duration: 300,
            easing: "easeInSine",
        });
        anime({
            targets: "#video-animation-overlay-2",
            delay: 200,
            height: "100%",
            duration: 600,
            easing: "easeInQuint",
            complete: () => {
                this.elements.videoOverlay.style.display = "flex";
                anime({
                    targets: "#video-overlay",
                    opacity: 1,
                    duration: 2500,
                    easing: "easeOutSine",
                    complete: () => {
                        this.elements.video.play();
                    },
                });
            },
        });
    }

    initMobileNav() {
        // Open Overlay
        this.elements.heroVideo.addEventListener("click", (e) => {
            // Need to use arrow function ! With a normal function, the this. is not referring to the one we want
            e.preventDefault();
            this.openVideoOverlay();
        });

        // Close Overlay
        this.elements.closeVideo.addEventListener("click", (e) => {
            e.preventDefault();
            this.closeVideoOverlay();
        });
        document.onkeydown = (e) => {
            e = e || window.event;
            if (e.key === "Escape") {
                this.closeVideoOverlay();
            }
        };
    }
}

export default VideoOverlay;
