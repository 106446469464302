import anime from "animejs";

class MobileNav {
    constructor() {
        this.elements = {
            body: document.querySelector("body"),
            hamburger: document.querySelector("#hamburger"),
            mobileNav: document.querySelector("#mobile-nav"),
            closeNav: document.querySelector("#close-nav"),
            navLinks: document.querySelectorAll("#mobile-nav ul li a"),
        };
        this.initMobileNav();
        this.toggleNavbarOnScroll();
    }

    closeNav() {
        anime({
            targets: "#mobile-nav li",
            translateY: -40,
            opacity: 0,
            duration: 100,
            delay: function (el, i, l) {
                return i * 50;
            },
            easing: "easeOutSine",
        });
        anime({
            targets: "#mobile-nav",
            opacity: 0,
            delay: 300,
            duration: 1000,
            easing: "easeOutSine",
            complete: () => {
                this.elements.mobileNav.style.display = "none";
            },
        });
    }

    openNav() {
        this.elements.mobileNav.style.display = "block";
        anime({
            targets: "#mobile-nav",
            opacity: 1,
            duration: 1000,
            easing: "easeOutSine",
        });
        anime({
            targets: "#mobile-nav li",
            translateY: 40,
            opacity: 1,
            duration: 500,
            delay: function (el, i, l) {
                return i * 100;
            },
            easing: "easeOutSine",
        });
    }

    initMobileNav() {
        // Open Nav
        this.elements.hamburger.addEventListener("click", (e) => {
            // Need to use arrow function ! With a normal function, the this. is not referring to the one we want
            e.preventDefault();
            this.openNav();
        });

        // Close Nav
        this.elements.closeNav.addEventListener("click", (e) => {
            e.preventDefault();
            this.closeNav();
        });

        // Click Link
        this.elements.navLinks.forEach((item) => {
            item.addEventListener("click", (e) => {
                this.closeNav();
            });
        });
    }

    toggleNavbarOnScroll() {
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("main-header").style.top = "0";
            } else if (window.pageYOffset > 60) {
                document.getElementById("main-header").style.top = "-100px";
            }
            prevScrollpos = currentScrollPos;
        };
    }
}

export default MobileNav;
